import { useCallback, useEffect, useState } from "react"

import { useWallet } from "use-wallet"

const LAST_CONNECTOR_KEY = "LAST_WALLET_CONNECTOR"
const WALLET_CONNECT_KEY = "walletconnect"

export default function useConnect(autoConnect = false) {
  const wallet = useWallet()
  const [errorState, setErrorState] = useState<string | null>()

  const onConnectMetamask = useCallback(async () => {
    try {
      const chainId = process.env.GATSBY_ETHERNET_CHAIN_ID as string

      if (chainId != window.ethereum.networkVersion) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
        })

        await wallet.connect("injected")

        new Promise(resolve => setTimeout(resolve, 300))
        // await timeout(300)
      }

      // return true
    } catch (error) {
      // return false
    }

    wallet.connect("injected")
  }, [wallet])

  const onConnectWalletconnect = useCallback(
    () => wallet.connect("walletconnect"),
    [wallet]
  )

  const disconnectWallet = () => {
    wallet.reset()
    const lastConnector = localStorage.getItem(LAST_CONNECTOR_KEY)
    if (lastConnector === "walletconnect") {
      localStorage.removeItem(WALLET_CONNECT_KEY)
    }
  }

  useEffect(() => {
    if (!autoConnect) {
      return
    }

    const lastConnector = localStorage.getItem(LAST_CONNECTOR_KEY)
    const walletConnectData = localStorage.getItem(WALLET_CONNECT_KEY)

    // if last connect provider was wallet connect and
    // walet connect data is still availible trigger wallet connect
    if (lastConnector === "walletconnect" && walletConnectData) {
      onConnectWalletconnect()
    }
  }, [])

  const clearErrors = () => setErrorState(null)

  return {
    errorState,
    wallet,
    clearErrors,
    onConnectMetamask,
    onConnectWalletconnect,
    disconnectWallet,
  }
}
