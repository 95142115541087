import metadynLogo from "../../images/PoweredByImage.png"
import * as styles from "./PoweredBy.module.scss"
import React from "react"

export const PoweredBy = () => {
  return (
    <div className={styles.poweredByContainer}>
      <a href="https://www.metadyn.com/" target="_blank" rel="noreferrer">
        <h1>Powered by:</h1>
        <img src={metadynLogo} alt="Metadyn" height="114px" />
      </a>
    </div>
  )
}
