import React from "react"

import { ICommonIconProps } from "."

const Play = ({
  width = "485",
  height = "485",
  viewBox = "0 0 485 485",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    //     <svg
    //       viewBox={viewBox}
    //       width={width}
    //       height={height}
    //       fill={fill}
    //       className={className}
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <g>
    //         <path
    //           d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30
    // c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15
    // C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"
    //         />
    //         <path
    //           d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30
    // S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
    //         />
    //       </g>
    //     </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      className={className}
    >
      <g>
        <path
          d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
		s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
		C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
		S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
        />
        <polygon points="181.062,336.575 343.938,242.5 181.062,148.425 	" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default Play
