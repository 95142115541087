import React from "react"
import Icon from "../../Icon"
import cn from "classnames"
import * as styles from "./MyWalletButton.module.scss"

interface MyWalletButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  className?: String
}

export const MyWalletButton = ({ onClick, className }: MyWalletButtonProps) => {
  return (
    <button className={cn(styles.MyWalletButton, className)} onClick={onClick}>
      <span>MY WALLET</span>
      <Icon name="metamask" width="40" height="40" />
    </button>
  )
}
