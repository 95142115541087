import React from "react"

import { ICommonIconProps } from "."

const NextIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  )
}

export default NextIcon
