import React from "react"

import { ICommonIconProps } from "."

const Twitter = ({
  fill = "#000",
  width = "16px",
  height = "12px",
  className = "",
  viewBox = "0 0 16 12",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6665 1.42049C15.0932 1.66179 14.4762 1.82455 13.8288 1.89739C14.4898 1.52271 14.9967 0.92921 15.2357 0.221212C14.6174 0.568617 13.933 0.820702 13.2039 0.956787C12.6205 0.368087 11.7888 0 10.8686 0C9.10179 0 7.66931 1.35635 7.66931 3.02952C7.66931 3.26662 7.69748 3.49773 7.75225 3.71984C5.09305 3.59335 2.73523 2.38747 1.15713 0.554529C0.881711 1.00175 0.724059 1.52211 0.724059 2.07783C0.724059 3.12874 1.28882 4.05615 2.14736 4.59929C1.62312 4.5834 1.12959 4.44702 0.6981 4.22011C0.697783 4.2327 0.697783 4.24559 0.697783 4.25848C0.697783 5.72603 1.80072 6.95019 3.26454 7.22896C2.99609 7.2979 2.71339 7.33507 2.42151 7.33507C2.21511 7.33507 2.01472 7.31618 1.81939 7.28051C2.22682 8.48399 3.40826 9.36014 4.80814 9.38442C3.71312 10.197 2.33382 10.6814 0.834542 10.6814C0.576536 10.6814 0.321697 10.667 0.0712891 10.6389C1.48763 11.4988 3.16925 12 4.97592 12C10.8613 12 14.0792 7.38392 14.0792 3.38053C14.0792 3.24924 14.0764 3.11825 14.0704 2.98816C14.6953 2.56192 15.2379 2.02808 15.6665 1.42049Z"
        fill={fill}
      />
    </svg>
  )
}

export default Twitter
