import React from "react"
import { RecoilRoot } from "recoil"
import { UseWalletProvider } from "use-wallet"

import Layout from "./src/components/Layout/Layout"

import "./src/scss/index.scss"

export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName("body")[0]
  body.scrollTop = 0
  return false
}

export const wrapRootElement = ({ element }) => {
  const chainId = parseInt(process.env.GATSBY_ETHERNET_CHAIN_ID)

  return (
    <UseWalletProvider
      autoConnect
      connectors={{
        walletconnect: {
          chainId: chainId,
          rpc: { [chainId]: process.env.GATSBY_REACT_APP_ETHEREUM_RPC },
        },
      }}
    >
      <RecoilRoot>{element}</RecoilRoot>
    </UseWalletProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
