// extracted by mini-css-extract-plugin
export var navBarDisconnectWalletButton = "NavBar-module--navBarDisconnectWalletButton--7e13b";
export var navBarMyWallet = "NavBar-module--navBarMyWallet--bdfe1";
export var navBarMyWalletButton = "NavBar-module--navBarMyWalletButton--19781";
export var navBarconnectWalletButton = "NavBar-module--navBarconnectWalletButton--37cb3";
export var navbar = "NavBar-module--navbar--c3801";
export var navbarActive = "NavBar-module--navbarActive--330f9";
export var navbarBtn = "NavBar-module--navbarBtn--dcc6e";
export var navbarClose = "NavBar-module--navbarClose--207f8";
export var navbarList = "NavBar-module--navbarList--b2e55";
export var navbarSocial = "NavBar-module--navbarSocial--f507e";
export var navbarSocialBox = "NavBar-module--navbarSocialBox--4b6d0";