import React, { useEffect, useState } from "react"

import * as styles from "./ConnectWalletModal.module.scss"

import { createPortal } from "react-dom"

import { IConnectWalletModal } from "./types"
import useConnect from "../../hooks/use-connect"
import Icon from "../../Icon"
import Container from "../Container/Container"
import { useWallet } from "use-wallet"
import { ConnectWalletMessage } from "../ConnectWalletMessage/ConnectWalletMessage"

const ConnectWalletModal = ({ closeModal }: IConnectWalletModal) => {
  const { onConnectMetamask, onConnectWalletconnect } = useConnect()
  const wallet = useWallet()
  const isConnected = wallet.status === "connected"
  const [isMetamask, setIsMetamask] = useState(false)
  useEffect(() => {
    if (window.ethereum) {
      setIsMetamask(true)
    }
  }, [])

  return createPortal(
    <div className={styles.connectWallet}>
      <Container>
        <div className={styles.connectWalletBackBtn}>
          <button onClick={() => closeModal(false)}>
            <Icon name="backArrow" />
            <span>My Wallet</span>
          </button>
        </div>
        {isConnected && (
          <div className={styles.connectWalletModalMessage}>
            <ConnectWalletMessage />
          </div>
        )}
        {!isConnected && (
          <div className={styles.connectWalletInner}>
            {isMetamask && (
              <button onClick={onConnectMetamask}>
                <Icon name="metamask" width="50" height="50" />
                <span className="text-lg">Metamask</span>
              </button>
            )}

            <button onClick={onConnectWalletconnect}>
              <Icon name="walletConnect" width="50" height="50" />
              <span className="text-lg">Wallet Connect</span>
            </button>

            {!isMetamask && (
              <div className={styles.connectWalletModalMetamaskInstall}>
                <span>Don't have Metamask installed?</span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://metamask.io/download/"
                >
                  <Icon name="metamask" width="50" height="50" />
                  Install Metamask
                </a>
              </div>
            )}
          </div>
        )}
      </Container>
    </div>,
    document.getElementById("___gatsby") as HTMLElement
  )
}

export default ConnectWalletModal
