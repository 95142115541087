import React from "react"

import { motion, AnimatePresence } from "framer-motion"

import * as styles from "./Menu.module.scss"
import NavBar from "../NavBar/NavBar"
import { createPortal } from "react-dom"

import { IMenu } from "./types"

const Menu = ({
  menuActive,
  setMenuActive,
  setShowConnectWallet,
  showConnectWallet,
}: IMenu) => {
  return createPortal(
    <motion.menu
      key="box"
      initial={{ x: "-50%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%", opacity: 0 }}
      className={styles.menu}
    >
      <NavBar
        setShowConnectWallet={setShowConnectWallet}
        showConnectWallet={showConnectWallet}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
      />
    </motion.menu>,
    document.getElementById("___gatsby") as HTMLElement
  )
}

export default Menu
