exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-all-artworks-index-tsx": () => import("./../../../src/pages/all-artworks/index.tsx" /* webpackChunkName: "component---src-pages-all-artworks-index-tsx" */),
  "component---src-pages-artist-collector-rights-index-tsx": () => import("./../../../src/pages/artist-collector-rights/index.tsx" /* webpackChunkName: "component---src-pages-artist-collector-rights-index-tsx" */),
  "component---src-pages-artists-index-tsx": () => import("./../../../src/pages/artists/index.tsx" /* webpackChunkName: "component---src-pages-artists-index-tsx" */),
  "component---src-pages-artworks-index-tsx": () => import("./../../../src/pages/artworks/index.tsx" /* webpackChunkName: "component---src-pages-artworks-index-tsx" */),
  "component---src-pages-collections-index-tsx": () => import("./../../../src/pages/collections/index.tsx" /* webpackChunkName: "component---src-pages-collections-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-create-account-index-tsx": () => import("./../../../src/pages/create-account/index.tsx" /* webpackChunkName: "component---src-pages-create-account-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-galleries-index-tsx": () => import("./../../../src/pages/galleries/index.tsx" /* webpackChunkName: "component---src-pages-galleries-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infocus-index-tsx": () => import("./../../../src/pages/infocus/index.tsx" /* webpackChunkName: "component---src-pages-infocus-index-tsx" */),
  "component---src-pages-open-call-index-tsx": () => import("./../../../src/pages/open-call/index.tsx" /* webpackChunkName: "component---src-pages-open-call-index-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-sign-up-artist-index-tsx": () => import("./../../../src/pages/sign-up/artist/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-artist-index-tsx" */),
  "component---src-pages-sign-up-confirmation-index-tsx": () => import("./../../../src/pages/sign-up/confirmation/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-confirmation-index-tsx" */),
  "component---src-pages-sign-up-gallery-index-tsx": () => import("./../../../src/pages/sign-up/gallery/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-gallery-index-tsx" */),
  "component---src-pages-sign-up-index-tsx": () => import("./../../../src/pages/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-whitelist-index-tsx": () => import("./../../../src/pages/whitelist/index.tsx" /* webpackChunkName: "component---src-pages-whitelist-index-tsx" */),
  "component---src-templates-artist-index-tsx": () => import("./../../../src/templates/artist/index.tsx" /* webpackChunkName: "component---src-templates-artist-index-tsx" */),
  "component---src-templates-artwork-detail-index-tsx": () => import("./../../../src/templates/artwork-detail/index.tsx" /* webpackChunkName: "component---src-templates-artwork-detail-index-tsx" */),
  "component---src-templates-artwork-index-tsx": () => import("./../../../src/templates/artwork/index.tsx" /* webpackChunkName: "component---src-templates-artwork-index-tsx" */),
  "component---src-templates-collection-index-tsx": () => import("./../../../src/templates/collection/index.tsx" /* webpackChunkName: "component---src-templates-collection-index-tsx" */),
  "component---src-templates-curator-index-tsx": () => import("./../../../src/templates/curator/index.tsx" /* webpackChunkName: "component---src-templates-curator-index-tsx" */),
  "component---src-templates-gallery-index-tsx": () => import("./../../../src/templates/gallery/index.tsx" /* webpackChunkName: "component---src-templates-gallery-index-tsx" */)
}

