import React from "react"

import { ICommonIconProps } from "."

const Close = ({
  width = "50",
  height = "50",
  viewBox = "0 0 50 50",
  fill = "none",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="16.8977"
        y1="32.0372"
        x2="32.1759"
        y2="16.759"
        stroke="white"
        strokeWidth="1.38504"
        strokeLinecap="round"
      />
      <line
        x1="17.8771"
        y1="16.759"
        x2="33.1553"
        y2="32.0372"
        stroke="white"
        strokeWidth="1.38504"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Close
