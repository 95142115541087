import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const PurpleSquares = ({
  fill = "none",
  width = "55",
  height = "80",
  className = "",
  viewBox = "0 0 55 80",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0247527 53.4358L27.5239 82L56.0002 54.4159L28.501 25.8517L0.0247527 53.4358Z"
      fill="#442AD3"
    />
    <path
      d="M0.0230438 51.7123L27.5222 80.2765L55.9984 52.6924L28.4993 24.1282L0.0230438 51.7123Z"
      fill="#4235D1"
    />
    <path
      d="M0.0215789 49.9888L27.5208 78.553L55.997 50.9689L28.4978 22.4047L0.0215789 49.9888Z"
      fill="#413FD0"
    />
    <path
      d="M0.0196258 48.2654L27.5188 76.8296L55.995 49.2455L28.4959 20.6813L0.0196258 48.2654Z"
      fill="#3F4ACE"
    />
    <path
      d="M0.0181609 46.5419L27.5173 75.1061L55.9936 47.522L28.4944 18.9578L0.0181609 46.5419Z"
      fill="#3E54CD"
    />
    <path
      d="M0.0166961 44.8184L27.5159 73.3826L55.9921 45.7984L28.4929 17.2343L0.0166961 44.8184Z"
      fill="#3C5FCB"
    />
    <path
      d="M0.0144988 43.095L27.5137 71.6592L55.9899 44.0751L28.4907 15.5109L0.0144988 43.095Z"
      fill="#3A69C9"
    />
    <path
      d="M0.013034 41.3715L27.5122 69.9357L55.9884 42.3516L28.4893 13.7874L0.013034 41.3715Z"
      fill="#3974C8"
    />
    <path
      d="M0.0115692 39.648L27.5107 68.2122L55.987 40.628L28.4878 12.0638L0.0115692 39.648Z"
      fill="#377FC6"
    />
    <path
      d="M0.00961603 37.9245L27.5088 66.4886L55.985 38.9045L28.4858 10.3403L0.00961603 37.9245Z"
      fill="#3589C4"
    />
    <path
      d="M0.00815118 36.2011L27.5073 64.7653L55.9835 37.1811L28.4844 8.61695L0.00815118 36.2011Z"
      fill="#3494C3"
    />
    <path
      d="M0.00668634 34.4776L27.5059 63.0417L55.9821 35.4576L28.4829 6.89344L0.00668634 34.4776Z"
      fill="#329EC1"
    />
    <path
      d="M0.00448907 32.754L27.5037 61.3182L55.9799 33.7341L28.4807 5.16993L0.00448907 32.754Z"
      fill="#31A9C0"
    />
    <path
      d="M0.00302423 31.0311L27.5022 59.5953L55.9784 32.0112L28.4792 3.44703L0.00302423 31.0311Z"
      fill="#2FB3BE"
    />
    <path
      d="M0.00155939 29.3076L27.5007 57.8718L55.977 30.2877L28.4778 1.72352L0.00155939 29.3076Z"
      fill="#2DBEBC"
    />
    <path
      d="M9.45435e-05 27.5841L27.4993 56.1483L55.9755 28.5642L28.4763 5.30436e-06L9.45435e-05 27.5841Z"
      fill="#2CC8BB"
    />
  </svg>
)

export default PurpleSquares
