import React from "react"
import { useRecoilState } from "recoil"
import { showConnectWalletState } from "../../state/connectWallet"

import * as styles from "./ConnectWalletButton.module.scss"

const ConnectWalletButton = () => {
  const [showConnectWallet, setShowConnectWallet] = useRecoilState(
    showConnectWalletState
  )

  return (
    <button
      className={styles.connectWalletButton}
      type="button"
      onClick={() => setShowConnectWallet(true)}
    >
      Connect Wallet
    </button>
  )
}

export default ConnectWalletButton
