import React from "react"

import Globe from "./Globe"
import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Twitter from "./Twitter"
import PurpleSquares from "./PurpleSquares"
import PurpleBlackSquares from "./PurpleBlackSquares"
import SkyBlueSquares from "./SkyBlueSquares"
import WalletConnect from "./WalletConnect"
import Metamask from "./Metamask"
import Close from "./Close"
import BackArrow from "./BackArrow"
import Play from "./Play"
import Pause from "./Pause"
import Warning from "./Warning"
import Mute from "./Mute"
import UnMute from "./UnMute"
import NextIcon from "./Next"
import PreviewIcon from "./Preview"
import Send from "./Send"
import GalleryBlueSquares from "./GalleryBlueSquares"
import ArtistSquare from "./ArtistSquare"
import CollectorSquare from "./CollectorSquare"
import SearchIcon from "./SearchIcon"
import CloseSquare from "./CloseSquare"

export interface ICommonIconProps {
  style?: React.CSSProperties
  fill?: string
  className?: string
  viewBox?: string
  width?: string | number
  height?: string | number
}

export type IconName =
  | "facebook"
  | "instagram"
  | "twitter"
  | "globe"
  | "purpleSquares"
  | "purpleBlackSquares"
  | "skyBlueSquares"
  | "galleryBlueSquares"
  | "collectorSquare"
  | "artistSquare"
  | "walletConnect"
  | "metamask"
  | "close"
  | "backArrow"
  | "play"
  | "pause"
  | "error"
  | "mute"
  | "unmute"
  | "next"
  | "preview"
  | "send"
  | "search"
  | "closeSquare"

interface IIconProps extends ICommonIconProps {
  name: IconName
}

const Icon = (props: IIconProps): JSX.Element => {
  switch (props.name) {
    case "globe":
      return <Globe {...props} />
    case "facebook":
      return <Facebook {...props} />
    case "instagram":
      return <Instagram {...props} />
    case "twitter":
      return <Twitter {...props} />
    case "purpleSquares":
      return <PurpleSquares {...props} />
    case "purpleBlackSquares":
      return <PurpleBlackSquares {...props} />
    case "skyBlueSquares":
      return <SkyBlueSquares {...props} />
    case "galleryBlueSquares":
      return <GalleryBlueSquares {...props} />
    case "collectorSquare":
      return <CollectorSquare {...props} />
    case "artistSquare":
      return <ArtistSquare {...props} />
    case "walletConnect":
      return <WalletConnect {...props} />
    case "metamask":
      return <Metamask {...props} />
    case "close":
      return <Close {...props} />
    case "backArrow":
      return <BackArrow {...props} />
    case "play":
      return <Play {...props} />
    case "pause":
      return <Pause {...props} />
    case "error":
      return <Warning {...props} />
    case "mute":
      return <Mute {...props} />
    case "unmute":
      return <UnMute {...props} />
    case "next":
      return <NextIcon {...props} />
    case "preview":
      return <PreviewIcon {...props} />
    case "send":
      return <Send {...props} />
    case "search":
      return <SearchIcon {...props} />
    case "closeSquare":
      return <CloseSquare {...props} />
    default:
      return <div />
  }
}

export default Icon
