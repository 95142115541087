import React from "react"

import { ICommonIconProps } from "."

const BackArrow = ({
  width = "23",
  height = "17",
  viewBox = "0 0 23 17",
  fill = "none",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.03606 1.7302C6.32436 1.4327 6.31686 0.957921 6.01936 0.669661C5.72196 0.381401 5.24716 0.388861 4.95886 0.686311L6.03606 1.7302ZM0.753036 5.0263C0.464776 5.3238 0.472236 5.7986 0.769686 6.0868C1.06715 6.3751 1.54196 6.3676 1.83022 6.0702L0.753036 5.0263ZM1.81407 5.0101C1.51688 4.7216 1.04206 4.7286 0.753516 5.0258C0.464986 5.323 0.472006 5.7978 0.769196 6.0864L1.81407 5.0101ZM4.97506 10.1697C5.27226 10.4582 5.74706 10.4512 6.03556 10.154C6.32406 9.8568 6.31706 9.382 6.01986 9.0935L4.97506 10.1697ZM1.29163 4.7983C0.877416 4.7983 0.541626 5.134 0.541626 5.5483C0.541626 5.9625 0.877416 6.2983 1.29163 6.2983V4.7983ZM16.7792 5.5483L16.7938 4.7984C16.7889 4.7983 16.7841 4.7983 16.7792 4.7983V5.5483ZM21.7083 10.6699L20.9585 10.6559C20.9583 10.6652 20.9583 10.6746 20.9585 10.684L21.7083 10.6699ZM16.7792 15.7916V16.5416C16.7841 16.5416 16.7889 16.5415 16.7938 16.5414L16.7792 15.7916ZM1.29163 15.0416C0.877416 15.0416 0.541626 15.3774 0.541626 15.7916C0.541626 16.2058 0.877416 16.5416 1.29163 16.5416V15.0416ZM4.95886 0.686311L0.753036 5.0263L1.83022 6.0702L6.03606 1.7302L4.95886 0.686311ZM0.769196 6.0864L4.97506 10.1697L6.01986 9.0935L1.81407 5.0101L0.769196 6.0864ZM1.29163 6.2983H16.7792V4.7983H1.29163V6.2983ZM16.7645 6.2981C19.1256 6.3443 21.0028 8.2947 20.9585 10.6559L22.4582 10.684C22.518 7.495 19.9827 4.8607 16.7938 4.7984L16.7645 6.2981ZM20.9585 10.684C21.0028 13.0451 19.1256 14.9956 16.7645 15.0417L16.7938 16.5414C19.9827 16.4791 22.518 13.8448 22.4582 10.6559L20.9585 10.684ZM16.7792 15.0416H1.29163V16.5416H16.7792V15.0416Z"
        fill="white"
      />
    </svg>
  )
}

export default BackArrow
