import React from "react"

import * as styles from "./Footer.module.scss"
import Container from "../Container/Container"
import { Link } from "gatsby"

import logoFooter from "../../images/DAFAx-logo-footer.png"
import SocialMedias from "../SocialMedias/SocialMedias"
import Icon from "../../Icon"
import { PoweredBy } from "../PoweredBy/PoweredBy"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerInner}>
          <div className={styles.footerImg}>
            <a
              href="https://www.digitalartfair.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoFooter} alt="Digital Art Fair" height="114px" />
            </a>
          </div>
          <div className={styles.footerAbout}>
            <Link to="/about">About</Link>
          </div>
          <div className={styles.footerArtist}>
            <Link to="/sign-up/artist">Artist Sign Up</Link>
          </div>
          <div className={styles.footerGallery}>
            <Link to="/sign-up/gallery">Gallery Sign Up</Link>
          </div>
          <div className={styles.footerCollectorsignUp}>
            <Link to="/create-account">Collector Sign Up</Link>
          </div>
          <div className={styles.footerSignUp}>
            <Link to="/sign-up">Sign Up</Link>
          </div>
          <div className={styles.footerWhitelist}>
            <Link to="/whitelist">Whitelist Invite</Link>
          </div>
          <div className={styles.footerOpenCall}>
            <Link to="/open-call">Open Call</Link>
          </div>
          <div className={styles.footerCollector}>
            <Link to="/artist-collector-rights">
              Artists and Collectors Rights
            </Link>
          </div>
          <div className={styles.footerFaq}>
            <Link to="/faq">FAQ</Link>
          </div>
          <div className={styles.footerContactUs}>
            <Link to="/contact-us">Contact Us</Link>
          </div>
          <div className={styles.footerTerms}>
            <Link to="/terms">Terms & Conditions</Link>
          </div>

          <div className={styles.footerContact}>
            <label>
              <span>Stay in touch with us</span>
              <div className={styles.footerEmailInput}>
                <input type="email" placeholder="Email" />
                <a href="mailto:info@dafax.io?subject=Please add me to your mailing list">
                  <Icon name="send" />
                </a>
              </div>
            </label>

            <SocialMedias classname={styles.footerSocialMedias} />
          </div>
        </div>
        <PoweredBy />
      </Container>
    </footer>
  )
}

export default Footer
