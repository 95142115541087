import React from "react"

import { ICommonIconProps } from "."

const Facebook = ({
  fill = "#000",
  width = "16px",
  height = "14px",
  className = "",
  viewBox = "0 0 16 14",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6268 2.33333C14.6268 1.10444 13.4786 0 12.2009 0H2.49722C1.21956 0 0.0712891 1.10444 0.0712891 2.33333V11.6667C0.0712891 12.8956 1.21956 14 2.49722 14H7.34907V8.71111H5.57005V6.37778H7.34907V5.47556C7.34907 3.90444 8.5782 2.48889 10.0823 2.48889H12.0392V4.82222H10.0823C9.87203 4.82222 9.61326 5.07111 9.61326 5.44444V6.37778H12.0392V8.71111H9.61326V14H12.2009C13.4786 14 14.6268 12.8956 14.6268 11.6667V2.33333Z"
        fill={fill}
      />
    </svg>
  )
}

export default Facebook
