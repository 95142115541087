import React from "react"
import classNames from "classnames"

import { IHamburger } from "./types"
import * as styles from "./Hamburger.module.scss"

const Hamburger = ({ active }: IHamburger) => {
  return (
    <div className={classNames(styles.hamburger, { [styles.open]: active })}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Hamburger
