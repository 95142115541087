import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const PurpleSquares = ({
  fill = "none",
  width = "93",
  height = "150",
  className = "",
  viewBox = "0 0 93 150",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.094 -0.000277881L0 46.0937L46.094 92.1877L92.1879 46.0937L46.094 -0.000277881Z"
      fill="#A70A6B"
    />
    <path
      d="M46.094 3.61306L0 49.707L46.094 95.801L92.1879 49.707L46.094 3.61306Z"
      fill="#A40A6F"
    />
    <path
      d="M46.094 7.22653L0 53.3205L46.094 99.4145L92.1879 53.3205L46.094 7.22653Z"
      fill="#A10A73"
    />
    <path
      d="M46.094 10.8399L0 56.9339L46.094 103.028L92.1879 56.9339L46.094 10.8399Z"
      fill="#9E0A76"
    />
    <path
      d="M46.094 14.4529L0 60.5468L46.094 106.641L92.1879 60.5468L46.094 14.4529Z"
      fill="#9B0A7A"
    />
    <path
      d="M46.094 18.0663L0 64.1602L46.094 110.254L92.1879 64.1602L46.094 18.0663Z"
      fill="#980A7E"
    />
    <path
      d="M46.094 21.6792L0 67.7732L46.094 113.867L92.1879 67.7732L46.094 21.6792Z"
      fill="#950A82"
    />
    <path
      d="M46.094 25.2926L0 71.3866L46.094 117.481L92.1879 71.3866L46.094 25.2926Z"
      fill="#920A85"
    />
    <path
      d="M46.094 28.9055L0 74.9995L46.094 121.093L92.1879 74.9995L46.094 28.9055Z"
      fill="#8F0A89"
    />
    <path
      d="M46.094 32.519L0 78.6129L46.094 124.707L92.1879 78.6129L46.094 32.519Z"
      fill="#8B0A8D"
    />
    <path
      d="M46.094 36.1324L0 82.2263L46.094 128.32L92.1879 82.2263L46.094 36.1324Z"
      fill="#880A91"
    />
    <path
      d="M46.094 39.7458L0 85.8398L46.094 131.934L92.1879 85.8398L46.094 39.7458Z"
      fill="#850A94"
    />
    <path
      d="M46.094 43.3587L0 89.4527L46.094 135.547L92.1879 89.4527L46.094 43.3587Z"
      fill="#820A98"
    />
    <path
      d="M46.094 46.9722L0 93.0661L46.094 139.16L92.1879 93.0661L46.094 46.9722Z"
      fill="#7F0A9C"
    />
    <path
      d="M46.094 50.5851L0 96.679L46.094 142.773L92.1879 96.679L46.094 50.5851Z"
      fill="#7C0AA0"
    />
    <path
      d="M46.094 54.1985L0 100.293L46.094 146.386L92.1879 100.293L46.094 54.1985Z"
      fill="#790AA3"
    />
    <path
      d="M46.094 57.8119L0 103.906L46.094 150L92.1879 103.906L46.094 57.8119Z"
      fill="black"
    />
    <path
      d="M46.0939 58.1487L0.336792 103.906L46.0939 149.663L91.8511 103.906L46.0939 58.1487Z"
      stroke="#681F9D"
    />
  </svg>
)

export default PurpleSquares
