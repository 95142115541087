import React from "react"

import { ICommonIconProps } from "."

const Pause = ({
  width = "512",
  height = "512",
  viewBox = "0 0 512 512",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M256,0C114.842,0,0,114.842,0,256s114.842,256,256,256s256-114.842,256-256S397.158,0,256,0z M256,465.455
c-115.493,0-209.455-93.961-209.455-209.455S140.507,46.545,256,46.545S465.455,140.507,465.455,256S371.493,465.455,256,465.455z
"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M318.061,139.636c-12.853,0-23.273,10.42-23.273,23.273v186.182c0,12.853,10.42,23.273,23.273,23.273
c12.853,0,23.273-10.42,23.273-23.273V162.909C341.333,150.056,330.913,139.636,318.061,139.636z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M193.939,139.636c-12.853,0-23.273,10.42-23.273,23.273v186.182c0,12.853,10.42,23.273,23.273,23.273
c12.853,0,23.273-10.42,23.273-23.273V162.909C217.212,150.056,206.792,139.636,193.939,139.636z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Pause
