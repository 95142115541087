// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--2d913";
export var footerAbout = "Footer-module--footerAbout--ca60e";
export var footerArtist = "Footer-module--footerArtist--e1dd2";
export var footerCollector = "Footer-module--footerCollector--49481";
export var footerCollectorsignUp = "Footer-module--footerCollectorsignUp--22b39";
export var footerContact = "Footer-module--footerContact--e3140";
export var footerContactUs = "Footer-module--footerContactUs--17341";
export var footerEmailInput = "Footer-module--footerEmailInput--c3bb6";
export var footerFaq = "Footer-module--footerFaq--5991a";
export var footerGallery = "Footer-module--footerGallery--5f9da";
export var footerImg = "Footer-module--footerImg--9e6f1";
export var footerInner = "Footer-module--footerInner--b3bd4";
export var footerOpenCall = "Footer-module--footerOpenCall--545b7";
export var footerSignUp = "Footer-module--footerSignUp--66f7a";
export var footerSocialMedias = "Footer-module--footerSocialMedias--01ade";
export var footerTerms = "Footer-module--footerTerms--c3983";
export var footerWhitelist = "Footer-module--footerWhitelist--b3603";