import React from "react"

import { Helmet } from "react-helmet"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import * as styles from "./Layout.module.scss"

const Layout = ({ children }: any) => {
  return (
    <>
      <Helmet />

      <div className={styles.layout}>
        <Header />
        <main>{children}</main>
      </div>

      <Footer />
    </>
  )
}

export default Layout
