import React from "react"

import { ICommonIconProps } from "."

const SearchIcon = ({
  width = "17",
  height = "22",
  viewBox = "0 0 17 22",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={"none"}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29987 14.6036C10.4772 14.6036 13.0529 11.8045 13.0529 8.35161C13.0529 4.89873 10.4772 2.09961 7.29987 2.09961C4.12258 2.09961 1.54688 4.89873 1.54688 8.35161C1.54688 11.8045 4.12258 14.6036 7.29987 14.6036Z"
        stroke={fill}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3086 14.9883L14.7536 19.5823"
        stroke={fill}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
