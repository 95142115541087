import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const ArtistSquare = ({
  fill = "none",
  width = "1590",
  height = "1590",
  className = "",
  viewBox = "0 0 1590 1590",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_479_1293)">
      <g opacity="0.382">
        <path
          d="M1135.26 1590V454.738L-0.000732422 454.738V1590H1135.26Z"
          fill="#E12D91"
        />
        <path d="M1590 1132.5V0L457.499 0V1132.5H1590Z" fill="#A065FF" />
        <path
          d="M1560.06 1165.2V29.942L424.797 29.942V1165.2H1560.06Z"
          fill="#A561F7"
        />
        <path
          d="M1524.66 1200.6V65.3402L389.398 65.3402V1200.6H1524.66Z"
          fill="#AA5CEE"
        />
        <path
          d="M1489.26 1236V100.738L353.998 100.738V1236H1489.26Z"
          fill="#AF58E6"
        />
        <path
          d="M1453.86 1271.4V136.141L318.595 136.141V1271.4H1453.86Z"
          fill="#B454DD"
        />
        <path
          d="M1418.46 1306.8V171.539L283.195 171.539V1306.8H1418.46Z"
          fill="#B94FD5"
        />
        <path
          d="M1383.06 1342.2V206.942L247.795 206.942V1342.2H1383.06Z"
          fill="#BE4BCC"
        />
        <path
          d="M1347.66 1377.6V242.34L212.396 242.34V1377.6H1347.66Z"
          fill="#C347C4"
        />
        <path
          d="M1314.87 1414.03V275.128L175.972 275.128V1414.03H1314.87Z"
          fill="#C843BC"
        />
        <path
          d="M1276.86 1448.4V313.141L141.598 313.141V1448.4H1276.86Z"
          fill="#CD3EB3"
        />
        <path
          d="M1241.46 1483.8V348.539L106.198 348.539V1483.8H1241.46Z"
          fill="#D23AAB"
        />
        <path
          d="M1206.06 1519.2V383.937L70.7991 383.937V1519.2H1206.06Z"
          fill="#D736A2"
        />
        <path
          d="M1170.66 1554.6V419.34L35.3987 419.34V1554.6H1170.66Z"
          fill="#DC319A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_479_1293">
        <rect width="1590" height="1590" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ArtistSquare
