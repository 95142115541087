import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const GalleryBlueSquares = ({
  fill = "none",
  width = "1517",
  height = "1613",
  className = "",
  viewBox = "0 0 1517 1613",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path d="M1032.86 501.77H0V1612.39H1032.86V501.77Z" fill="#442AD3" />
      <path
        d="M1065.13 468.323H32.2783V1578.95H1065.13V468.323Z"
        fill="#4235D1"
      />
      <path
        d="M1097.41 434.868H64.5518V1545.49H1097.41V434.868Z"
        fill="#413FD0"
      />
      <path
        d="M1129.69 401.417H96.835V1512.04H1129.69V401.417Z"
        fill="#3F4ACE"
      />
      <path
        d="M1161.96 367.961H129.107V1478.59H1161.96V367.961Z"
        fill="#3E54CD"
      />
      <path
        d="M1194.24 334.515H161.386V1445.14H1194.24V334.515Z"
        fill="#3C5FCB"
      />
      <path
        d="M1226.52 301.064H193.664V1411.69H1226.52V301.064Z"
        fill="#3A69C9"
      />
      <path
        d="M1258.79 267.608H225.938V1378.23H1258.79V267.608Z"
        fill="#3974C8"
      />
      <path
        d="M1291.07 234.162H258.217V1344.79H1291.07V234.162Z"
        fill="#377FC6"
      />
      <path
        d="M1323.35 200.707H290.495V1311.33H1323.35V200.707Z"
        fill="#3589C4"
      />
      <path
        d="M1355.63 167.256H322.772V1277.88H1355.63V167.256Z"
        fill="#3494C3"
      />
      <path
        d="M1387.91 133.808H355.051V1244.43H1387.91V133.808Z"
        fill="#329EC1"
      />
      <path
        d="M1420.18 100.353H387.324V1210.98H1420.18V100.353Z"
        fill="#31A9C0"
      />
      <path
        d="M1452.45 66.9022H419.598V1177.53H1452.45V66.9022Z"
        fill="#2FB3BE"
      />
      <path
        d="M1484.73 33.451H451.871V1144.08H1484.73V33.451Z"
        fill="#2DBEBC"
      />
      <path
        d="M1517 -0.00012207H484.145V1110.62H1517V-0.00012207Z"
        fill="#2CC8BB"
      />
    </g>
  </svg>
)

export default GalleryBlueSquares
