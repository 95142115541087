import React from "react"
import cn from "classnames"

import * as styles from "./DisconnectWalletButton.module.scss"
import useConnect from "../../hooks/use-connect"

interface DisconnectWalletButtonProps {
  className?: String
}

export const DisconnectWalletButton = ({
  className,
}: DisconnectWalletButtonProps) => {
  const { disconnectWallet } = useConnect()

  return (
    <button
      className={cn(styles.disconnectWalletButton, className)}
      type="button"
      onClick={() => disconnectWallet()}
    >
      DISCONNECT WALLET
    </button>
  )
}
