import React from "react"

import { ICommonIconProps } from "."

const Warning = ({
  width = "1em",
  height = "1em",
  viewBox = "64 64 896 896",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 464 720 a 48 48 0 1 0 96 0 a 48 48 0 1 0 -96 0 Z m 16 -304 v 184 c 0 4.4 3.6 8 8 8 h 48 c 4.4 0 8 -3.6 8 -8 V 416 c 0 -4.4 -3.6 -8 -8 -8 h -48 c -4.4 0 -8 3.6 -8 8 Z m 475.7 440 l -416 -720 c -6.2 -10.7 -16.9 -16 -27.7 -16 s -21.6 5.3 -27.7 16 l -416 720 C 56 877.4 71.4 904 96 904 h 832 c 24.6 0 40 -26.6 27.7 -48 Z m -783.5 -27.9 L 512 239.9 l 339.8 588.2 H 172.2 Z" />
    </svg>
  )
}

export default Warning
