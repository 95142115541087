import classNames from "classnames"
import React from "react"
import Icon from "../../Icon"

import * as styles from "./SocialMedias.module.scss"
import { ISocialMedias } from "./types"

const SocialMedias = ({ classname }: ISocialMedias) => {
  return (
    <div className={classNames(styles.socialMedias, classname)}>
      <ul>
        <li>
          <a
            href="https://www.instagram.com/dafax.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="instagram" fill="white" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/dafaxnft"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="twitter" fill="white" />
          </a>
        </li>
        <li>
          <a
            href=" https://www.digitalartfair.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="globe" fill="white" />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SocialMedias
