import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const CollectorSquare = ({
  fill = "none",
  width = "1813",
  height = "1848",
  className = "",
  viewBox = "0 0 1813 1848",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 562.678L0 1692.76H1130.08V562.678H0Z" fill="#8911FF" />
    <path
      d="M26.9097 538.27L26.9097 1665.85H1154.49V538.27H26.9097Z"
      fill="#8D10F7"
    />
    <path
      d="M53.8247 511.357L53.8247 1638.93H1181.4V511.357H53.8247Z"
      fill="#910FEF"
    />
    <path
      d="M80.3823 482.296L80.3823 1612.38H1210.47V482.296H80.3823Z"
      fill="#950FE7"
    />
    <path
      d="M107.652 457.533L107.652 1585.11H1235.23V457.533H107.652Z"
      fill="#9A0EDF"
    />
    <path
      d="M134.564 430.619L134.564 1558.2H1262.14V430.619H134.564Z"
      fill="#9E0DD7"
    />
    <path
      d="M165.495 401.913L165.495 1527.27H1290.85V401.913H165.495Z"
      fill="#A20CCF"
    />
    <path
      d="M188.392 376.791L188.392 1504.37H1315.97V376.791H188.392Z"
      fill="#A60BC7"
    />
    <path
      d="M215.307 349.877L215.307 1477.45H1342.88V349.877H215.307Z"
      fill="#AA0BBF"
    />
    <path
      d="M245.877 326.26L245.877 1446.89H1366.5V326.26H245.877Z"
      fill="#AE0AB7"
    />
    <path
      d="M269.134 296.05L269.134 1423.63H1396.71V296.05H269.134Z"
      fill="#B209AF"
    />
    <path
      d="M296.043 269.135L296.043 1396.71H1423.62V269.135H296.043Z"
      fill="#B708A6"
    />
    <path
      d="M326.259 245.876L326.259 1366.5H1446.89V245.876H326.259Z"
      fill="#BB079E"
    />
    <path
      d="M349.871 215.307L349.871 1342.88H1477.45V215.307H349.871Z"
      fill="#BF0696"
    />
    <path
      d="M376.783 188.398L376.783 1315.98H1504.36V188.398H376.783Z"
      fill="#C3068E"
    />
    <path
      d="M401.911 165.494L401.911 1290.85H1527.27V165.494H401.911Z"
      fill="#C70586"
    />
    <path
      d="M430.616 134.57L430.616 1262.15H1558.19V134.57H430.616Z"
      fill="#CB047E"
    />
    <path
      d="M457.531 107.657L457.531 1235.23H1585.11V107.657H457.531Z"
      fill="#CF0376"
    />
    <path
      d="M482.296 80.3829L482.296 1210.47H1612.38V80.3829H482.296Z"
      fill="#D4026E"
    />
    <path
      d="M511.355 53.8284L511.355 1181.41H1638.93V53.8284H511.355Z"
      fill="#D80266"
    />
    <path
      d="M538.265 26.9141L538.265 1154.49H1665.84V26.9141H538.265Z"
      fill="#DC015E"
    />
    <path
      d="M562.675 0.000732422L562.675 1130.08H1692.76V0.000732422H562.675Z"
      fill="#EA137F"
    />
  </svg>
)

export default CollectorSquare
