import React from "react"

import { Link } from "gatsby"
import classNames from "classnames"

import SocialMedias from "../SocialMedias/SocialMedias"
import { INavBar } from "./types"
import ConnectWalletModal from "../ConnectWalletModal/ConnectWalletModal"

import * as styles from "./NavBar.module.scss"
import Icon from "../../Icon"
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton"
import { useWallet } from "use-wallet"
import { MyWalletButton } from "../MyWalletButton/MyWalletButton"
import { SearchInput } from "../SearchInput/SearchInput"

const NavBar = ({
  menuActive,
  setMenuActive,
  setShowConnectWallet,
  showConnectWallet,
}: INavBar) => {
  const wallet = useWallet()
  return (
    <div
      className={classNames(styles.navbar, {
        [styles.navbarActive]: menuActive,
      })}
    >
      <ul className={styles.navbarList}>
        <li>
          <Link
            to="/about"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            ABOUT
          </Link>
        </li>
        <li>
          <a
            href="https://www.digitalartfair.io/"
            target="_blank"
            rel="noreferrer"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            ART FAIR
          </a>
        </li>
        <li>
          <Link
            to="/artists"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            ARTISTS
          </Link>
        </li>
        <li>
          <Link
            to="/galleries"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            GALLERIES
          </Link>
        </li>
        <li>
          <Link
            to="/collection/xperience-2022"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            Buy NFTs
          </Link>
          {/* <a>
            COLLECT
            <ul>
              <li>
                {" "}
                <Link
                  to="/artworks"
                  onClick={() => setMenuActive && setMenuActive(false)}
                >
                  ARTWORKS
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/collections"
                  onClick={() => setMenuActive && setMenuActive(false)}
                >
                  COLLECTIONS
                </Link>
              </li>
            </ul>
          </a> */}
        </li>
        {/* <li>
          <Link
            to="/infocus"
            onClick={() => setMenuActive && setMenuActive(false)}
          >
            INFOCUS
          </Link>
        </li> */}
        {/* <li>
          <SearchInput />
        </li> */}
        <li className={styles.navbarBtn}>
          {showConnectWallet ? (
            <div className={styles.navbarClose}>
              <button onClick={() => setShowConnectWallet(false)}>
                <Icon name="close" fill="#fff" width="43px" height="43px" />
              </button>
            </div>
          ) : wallet.status !== "connected" ? (
            <div className={styles.navBarconnectWalletButton}>
              <ConnectWalletButton />
            </div>
          ) : (
            <div className={styles.navBarMyWalletButton}>
              <MyWalletButton
                className={styles.navBarMyWallet}
                onClick={() => setShowConnectWallet(!showConnectWallet)}
              />
            </div>
          )}
        </li>
        <li className={styles.navbarSocialBox}>
          <SocialMedias classname={styles.navbarSocial} />
        </li>
      </ul>

      {showConnectWallet && (
        <ConnectWalletModal closeModal={setShowConnectWallet} />
      )}
    </div>
  )
}

export default NavBar
