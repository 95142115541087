import React from "react"

import { ICommonIconProps } from "."

type IPurpleSquares = ICommonIconProps

const PurpleSquares = ({
  fill = "none",
  width = "80",
  height = "80",
  className = "",
  viewBox = "0 0 80 80",
}: IPurpleSquares): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M53.0326 0H0V53.059H53.0326V0Z" fill="#681F9D" />
    <path d="M54.6899 1.658H1.65723V54.717H54.6899V1.658Z" fill="#70209C" />
    <path d="M56.347 3.31606H3.31439V56.3751H56.347V3.31606Z" fill="#77219C" />
    <path
      d="M58.0043 4.97406H4.97162V58.0331H58.0043V4.97406Z"
      fill="#7F229B"
    />
    <path
      d="M59.6614 6.63248H6.62878V59.6915H59.6614V6.63248Z"
      fill="#86239A"
    />
    <path d="M61.319 8.2905H8.28638V61.3495H61.319V8.2905Z" fill="#8E2399" />
    <path d="M62.9762 9.94855H9.9436V63.0076H62.9762V9.94855Z" fill="#952499" />
    <path
      d="M64.6334 11.6066H11.6008V64.6656H64.6334V11.6066Z"
      fill="#9D2598"
    />
    <path d="M66.2906 13.2646H13.258V66.3236H66.2906V13.2646Z" fill="#A52697" />
    <path d="M67.9478 14.923H14.9152V67.982H67.9478V14.923Z" fill="#AC2796" />
    <path d="M69.6054 16.581H16.5728V69.64H69.6054V16.581Z" fill="#B42896" />
    <path d="M71.2626 18.239H18.23V71.298H71.2626V18.239Z" fill="#BB2995" />
    <path d="M72.9198 19.897H19.8871V72.9561H72.9198V19.897Z" fill="#C32A94" />
    <path d="M74.577 21.555H21.5444V74.6141H74.577V21.555Z" fill="#CA2A93" />
    <path
      d="M76.2345 23.2135H23.2019V76.2725H76.2345V23.2135Z"
      fill="#D22B93"
    />
    <path
      d="M77.8918 24.8715H24.8591V77.9305H77.8918V24.8715Z"
      fill="#D92C92"
    />
    <path
      d="M79.5489 26.5295H26.5163V79.5885H79.5489V26.5295Z"
      fill="#E12D91"
    />
  </svg>
)

export default PurpleSquares
