import React, {  useMemo, useState } from "react"

import { Link } from "gatsby"
import NavBar from "../NavBar/NavBar"

import * as styles from "./Header.module.scss"
import Menu from "../Menu/Menu"
import Hamburger from "../Hamburger/Hamburger"
import classNames from "classnames"
import Container from "../Container/Container"

import logo from "../../images/DAFAx-logo-white.png"
import { useRecoilState } from "recoil"
import { showConnectWalletState } from "../../state/connectWallet"
import useConnect from "../../hooks/use-connect"

const Header = () => {
  const {wallet} = useConnect(true)

  const [showMenu, setShowMenu] = useState(false)
  const [showConnectWallet, setShowConnectWallet] = useRecoilState(
    showConnectWalletState
  )

  useMemo(() => {
    if (wallet.status === "connected") {
      setShowConnectWallet(false)
      setShowMenu(false)

      if (wallet.connector === "walletconnect") {
        if (wallet.ethereum) {
          wallet.ethereum.on = null
        }
      }
    }
  }, [wallet.status, wallet.connector, wallet.ethereum, setShowConnectWallet])

  return (
    <header
      className={classNames(styles.header, { [styles.headerActive]: showMenu })}
    >
      <Container>
        <div className={styles.headerInner}>
          <Link
            to="/"
            className={styles.headerLogo}
            onClick={() => setShowMenu(false)}
          >
            <img src={logo} alt="DAFAx" height="40px" />
          </Link>

          <NavBar
            setShowConnectWallet={setShowConnectWallet}
            showConnectWallet={showConnectWallet}
          />

          <button
            onClick={() => {
              setShowMenu(!showMenu)
              setShowConnectWallet(false)
            }}
            className={styles.headerMenuToggle}
          >
            <Hamburger active={showMenu} />
          </button>
        </div>
      </Container>

      {showMenu && (
        <Menu
          key="box"
          menuActive={showMenu}
          setMenuActive={setShowMenu}
          setShowConnectWallet={setShowConnectWallet}
          showConnectWallet={showConnectWallet}
        />
      )}
    </header>
  )
}

export default Header
