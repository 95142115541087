import React from "react"

import { ICommonIconProps } from "."

const CloseSquare = ({
  width = "29",
  height = "29",
  viewBox = "0 0 29 29",
  fill = "#FF0000",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={"none"}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.991779"
        y="0.991779"
        width="26.5797"
        height="26.5797"
        rx="3.76876"
        fill={fill}
      />
      <path
        d="M7.69478 19.6003C7.3462 19.9489 7.3462 20.514 7.69478 20.8626C8.04336 21.2112 8.60853 21.2112 8.95711 20.8626L7.69478 19.6003ZM14.9078 14.9119C15.2563 14.5633 15.2563 13.9982 14.9078 13.6496C14.5592 13.3011 13.9941 13.3011 13.6455 13.6496L14.9078 14.9119ZM13.6455 13.6496C13.2969 13.9982 13.2969 14.5633 13.6455 14.9119C13.9941 15.2605 14.5592 15.2605 14.9078 14.9119L13.6455 13.6496ZM20.8584 8.96126C21.207 8.61268 21.207 8.04751 20.8584 7.69893C20.5098 7.35036 19.9448 7.35036 19.5962 7.69893L20.8584 8.96126ZM14.9078 13.6496C14.5592 13.3011 13.9941 13.3011 13.6455 13.6496C13.2969 13.9982 13.2969 14.5633 13.6455 14.9119L14.9078 13.6496ZM19.5962 20.8626C19.9448 21.2112 20.5098 21.2112 20.8584 20.8626C21.207 20.514 21.207 19.9489 20.8584 19.6003L19.5962 20.8626ZM13.6455 14.9119C13.9941 15.2605 14.5592 15.2605 14.9078 14.9119C15.2563 14.5633 15.2563 13.9982 14.9078 13.6496L13.6455 14.9119ZM8.95711 7.69893C8.60853 7.35036 8.04336 7.35036 7.69478 7.69893C7.3462 8.04751 7.3462 8.61268 7.69478 8.96126L8.95711 7.69893ZM8.95711 20.8626L14.9078 14.9119L13.6455 13.6496L7.69478 19.6003L8.95711 20.8626ZM14.9078 14.9119L20.8584 8.96126L19.5962 7.69893L13.6455 13.6496L14.9078 14.9119ZM13.6455 14.9119L19.5962 20.8626L20.8584 19.6003L14.9078 13.6496L13.6455 14.9119ZM14.9078 13.6496L8.95711 7.69893L7.69478 8.96126L13.6455 14.9119L14.9078 13.6496Z"
        fill="white"
      />
      <rect
        x="0.991779"
        y="0.991779"
        width="26.5797"
        height="26.5797"
        rx="3.76876"
        stroke="white"
        stroke-width="1.98356"
      />
    </svg>
  )
}

export default CloseSquare
