import Icon from "../../Icon"
import React from "react"
import * as styles from "./ConnectWalletMessage.module.scss"
import { useWallet } from "use-wallet"
import warningWhite from "../../images/warningWhite.png"
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton"
import { DisconnectWalletButton } from "../DisconnectWalletButton/DisconnectWalletButton"

export const ConnectWalletMessage = () => {
  const wallet = useWallet()
  const isConnected = wallet.status === "connected"

  if (isConnected)
    return (
      <>
        <div className={styles.ConnectedWalletMessageContainer}>
          <span>Wallet succesfully connected</span>
          <Icon name="metamask" width="50" height="50" />
        </div>
        <div className={styles.ConnectedWalletMessageWalletAddress}>
          <span>{`Wallet Address: ${shortAddress(wallet?.account!)}`}</span>
        </div>
        <div className={styles.ConnectedWalletMessageConnectWalletButton}>
          <DisconnectWalletButton />
        </div>
      </>
    )
  else
    return (
      <>
        <div className={styles.DisconnectedWalletMessageContainer}>
          <span>No crypto wallet detected</span>
          <img src={warningWhite} alt="Warning" />
        </div>
        <div className={styles.ConnectedWalletMessageConnectWalletButton}>
          <ConnectWalletButton />
        </div>
      </>
    )
}

export function shortAddress(address?: string, padding = 4) {
  address = address ? address : ""
  return address.length
    ? `${address.slice(0, padding)}....${address.slice(padding * -1)}`
    : ""
}
