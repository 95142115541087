import React from "react"

import { ICommonIconProps } from "."

const Send = ({
  width = "24",
  height = "24",
  viewBox = "0 0 24 24",
  fill = "white",
  className = "",
}: ICommonIconProps): JSX.Element => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          fill={fill}
          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
        />
      </g>
    </svg>
  )
}

export default Send
